// src/apiService.js
import axios from "axios";

const API_BASE_URL = "https://us-central1-matchdeal-9fcc3.cloudfunctions.net";

export const getExactItemByIds = async (ids) => {
  const response = await axios.get(`${API_BASE_URL}/getExactItemByIds/`, {
    params: { ids },
  });
  return response.data;
};

export const getExactItemsByCategory = async (
  start,
  limit,
  category,
  availableItemsOnly,
  sortOrder,
  collectionName,
  deployDate
) => {
  const response = await axios.get(`${API_BASE_URL}/getExactItemsByCategory/`, {
    params: {
      start,
      limit,
      category,
      availableItemsOnly,
      sortOrder,
      collectionName,
      deployDate,
    },
  });
  return response.data;
};

export const getSimilarItemByIds = async (ids) => {
  const response = await axios.get(`${API_BASE_URL}/getSimilarItemByIds/`, {
    params: { ids },
  });
  return response.data;
};

export const getSimilarItemsByCategory = async (
  start,
  limit,
  category,
  availableItemsOnly,
  sortOrder,
  collectionName,
  deployDate
) => {
  const response = await axios.get(
    `${API_BASE_URL}/getSimilarItemsByCategory/`,
    {
      params: {
        start,
        limit,
        category,
        availableItemsOnly,
        sortOrder,
        collectionName,
        deployDate,
      },
    }
  );
  return response.data;
};
