import React, { useContext, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FaStar, FaHeart } from "react-icons/fa";
import { WishlistContext } from "../../context/WishlistContext";
import "./SimilarComparison.css";
import { trackButtonClick, trackComponentDisplay } from "../../utils/analytics";
import amazonLogo from "../../images/amazon_logo.png";
import temuLogo from "../../images/temu_logo.png";

const SimilarComparison = React.forwardRef((props, ref) => {
  useEffect(() => {
    trackComponentDisplay("SimilarComparison", {
      source: props.source,
      id: props.id,
    });
  }, []);

  const { wishlist, addToWishlist, removeFromWishlist } =
    useContext(WishlistContext);

  const isInWishlist = wishlist.some((product) => product.id === props.id);

  const toggleWishlist = () => {
    if (isInWishlist) {
      removeFromWishlist(props.id);
      trackButtonClick("Remove from Wishlist", props.id);
    } else {
      addToWishlist(props.id);
      trackButtonClick("Add to Wishlist", props.id);
    }
  };

  // Determine the class based on the source
  const productCardClass = `similar-product-card ${props.source}`;
  const numberFormatter = new Intl.NumberFormat();

  return (
    <Col
      xs={6}
      sm={6}
      md={6}
      lg={6}
      xl={6}
      style={{ padding: "0px", margin: "0px" }}
    >
      <a
        href={props.affiliateLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() =>
          trackButtonClick(
            props.source === "amazon"
              ? "Similar Amazon Affiliate"
              : "Similar Temu Affiliate",
            props.id
          )
        }
      >
        <Card
          className={`${productCardClass} mb-0 ${
            props.source === "amazon" ? "amazon-border-radius" : ""
          } ${props.source === "temu" ? "temu-border-radius" : ""}`}
        >
          {props.soldout && (
            <div className="similar-sold-out-patch">SOLD OUT</div>
          )}
          <div className="card-content">
            <div
              style={{
                position: "relative",
                width: "100%",
                paddingTop: "100%",
              }}
            >
              <Card.Img
                variant="top"
                src={props.thumbnail}
                alt="Product"
                loading="lazy"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  padding: 10,
                }}
              />
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleWishlist();
                }}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <FaHeart
                  className={`similar-wishlist-icon ${
                    isInWishlist ? "in-wishlist" : ""
                  }`}
                />
              </div>
            </div>
            <Card.Body>
              <Card.Title
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  minHeight: "3.6em",
                  fontSize: "16px",
                }}
              >
                {props.name}
              </Card.Title>
              {/* <Card.Text
                  className="product-price"
                  style={{ textDecoration: "line-through" }}
                >
                  ${props.originalPrice}
                </Card.Text> */}
            </Card.Body>
          </div>
        </Card>
        <div
          className="similar-product-info-container"
          style={{
            borderBottomRightRadius: props.source === "amazon" ? "0" : "15px",
            borderBottomLeftRadius: props.source === "temu" ? "0" : "15px",
          }}
        >
          <div
            className={`similar-product-info similar-single-info ${
              props.source === "amazon"
                ? "similar-amazon-info"
                : "similar-temu-info"
            }`}
          >
            <div className="similar-store-container">
              {/* <span className="store-name">
                  {props.source === "amazon" ? "Amazon" : "Temu"}
                </span> */}
              <div className="similar-discount-info">
                {props.discount === "0%"
                  ? "Re-stocked"
                  : `${props.discount} OFF`}
              </div>
            </div>
            <div className="similar-price-info">${props.currentPrice}</div>
            <div className={`similar-price-info-amazon-aux`}>
              {props.source === "amazon"
                ? `New: ${
                    props.amazonNewPrice ? `$${props.amazonNewPrice}` : "N/A"
                  }, Used: ${
                    props.amazonUsedPrice ? `$${props.amazonUsedPrice}` : "N/A"
                  }`
                : "\u00A0"}
            </div>

            <div className="similar-rating-info">
              <span className="similar-star-icon">★</span>
              {props.rating}
              <span className="similar-purchases">
                ({numberFormatter.format(props.purchases)})
              </span>
            </div>
            <div
              className={`similar-shop-now ${
                props.source === "amazon"
                  ? "similar-shop-now-amazon"
                  : "similar-shop-now-temu"
              }`}
              style={{ position: "relative" }}
            >
              <div
                className="similar-logo-icon"
                style={{
                  position: "absolute",
                  top: -10,
                  ...(props.source === "amazon"
                    ? { right: -15 }
                    : { left: -15 }),
                  width: "40px",
                  height: "40px",
                }}
              >
                <img
                  src={props.source === "amazon" ? amazonLogo : temuLogo}
                  alt={`${props.source} logo`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </div>
              Buy Now
            </div>
          </div>
        </div>
      </a>
    </Col>
  );
});

export default SimilarComparison;
