import React from "react";
import "./FilterPanel.css";
import { trackButtonClick } from "../../utils/analytics";

export const CATEGORY_ENUM = {
  // ALL: "all",
  LIVING: "living",
  FASHION_BEAUTY: "fashion_beauty",
  // ELECTRONICS: "electronics",
  KITCHEN: "kitchen",
};

const FilterPanel = ({
  currentCategory,
  updateSelection,
  activeTab,
  exactCategories,
  similarCategories,
}) => {
  // Helper function to format category names
  const formatCategoryName = (category) => {
    if (category === CATEGORY_ENUM.FASHION_BEAUTY) {
      return "Women Clothing";
    } else if (category === CATEGORY_ENUM.LIVING) {
      return "Home Improvement";
    } else if (category === CATEGORY_ENUM.KITCHEN) {
      return "Home & Kitchen";
    }
    return category.charAt(0).toUpperCase() + category.slice(1);
  };

  // Define categories for each tab using props
  const tabCategories = {
    exact: exactCategories || [
      CATEGORY_ENUM.ALL,
      CATEGORY_ENUM.LIVING,
      CATEGORY_ENUM.ELECTRONICS,
      CATEGORY_ENUM.FASHION_BEAUTY,
    ],
    similar: similarCategories || [
      // CATEGORY_ENUM.ALL,
      CATEGORY_ENUM.FASHION_BEAUTY,
      CATEGORY_ENUM.LIVING,
      // CATEGORY_ENUM.ELECTRONICS,
    ],
  };

  // Determine categories based on the active tab
  const activeCategories = tabCategories[activeTab];

  return (
    <div className="filter-panel-horizontal">
      {activeCategories.map((category) => (
        <button
          key={category}
          onClick={() => {
            updateSelection({ currentCategory: category });
            trackButtonClick(
              "Filter selected",
              category + " in " + activeTab + " tab"
            );
          }}
          className={`category-button-horizontal ${
            currentCategory === category ? "selected" : ""
          }`}
        >
          {formatCategoryName(category)}
        </button>
      ))}
    </div>
  );
};

export default FilterPanel;
