import React, { useContext, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FaStar, FaHeart } from "react-icons/fa";
import { WishlistContext } from "../../context/WishlistContext";
import "./ProductComparison.css";
import { trackButtonClick, trackComponentDisplay } from "../../utils/analytics";
import amazonLogo from "../../images/amazon_logo.png";
import temuLogo from "../../images/temu_logo.png";

const ProductComparison = React.forwardRef((props, ref) => {
  useEffect(() => {
    trackComponentDisplay("ProductComparison", {
      id: props.id,
    });
  }, []);

  const { wishlist, addToWishlist, removeFromWishlist } =
    useContext(WishlistContext);

  const isInWishlist = wishlist.some((product) => product.id === props.id);

  const toggleWishlist = () => {
    if (isInWishlist) {
      removeFromWishlist(props.id);
      trackButtonClick("Remove from Wishlist", props.id);
    } else {
      addToWishlist(props.id);
      trackButtonClick("Add to Wishlist", props.id);
    }
  };

  // Create a number formatter for the current locale
  const numberFormatter = new Intl.NumberFormat();

  return (
    <Row className={`justify-content-center mb-5`} ref={ref}>
      <Col
        xs={11}
        sm={11}
        md={11}
        lg={11}
        xl={11}
        className={props.soldout ? "exact-sold-out" : ""}
        style={{ padding: "0px", margin: "0px" }}
      >
        <Card className="exact-product-card mb-0">
          {props.soldout && (
            <div className="exact-sold-out-patch">SOLD OUT</div>
          )}
          <div className="card-content">
            <div
              style={{
                position: "relative",
                width: "100%",
                paddingTop: "100%",
              }}
            >
              <Card.Img
                variant="top"
                src={props.thumbnail}
                loading="lazy"
                alt="Product"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  padding: 20,
                }}
              />
              <div
                onClick={toggleWishlist}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <FaHeart
                  className={`exact-wishlist-icon ${
                    isInWishlist ? "in-wishlist" : ""
                  }`}
                />
              </div>
            </div>
            <Card.Body>
              <Card.Title
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  minHeight: "3em",
                  fontSize: "16px",
                }}
              >
                {props.name}
              </Card.Title>
            </Card.Body>
          </div>
        </Card>
        <div className="exact-product-info-container">
          <div className="exact-left-info">
            <a
              href={props.amazonAffiliateLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                trackButtonClick("Exact Amazon Affiliate", props.id)
              }
            >
              <div className="exact-store-container">
                <div className="exact-discount-info">
                  {props.discount === "0%"
                    ? "Re-stocked"
                    : `${props.amazonDiscount} OFF`}
                </div>
              </div>
              <div className="exact-price-info">
                ${props.amazonCurrentPrice}
              </div>
              <div className={`exact-price-info-amazon-aux`}>
                New: {props.amazonNewPrice ? `$${props.amazonNewPrice}` : "N/A"}
                , Used:{" "}
                {props.amazonUsedPrice ? `$${props.amazonUsedPrice}` : "N/A"}
              </div>
              <div className="exact-rating-info">
                <FaStar className="exact-star-icon" />
                <span className="exact-rating">{props.amazonRating}</span>
                <span className="exact-purchases">
                  ({numberFormatter.format(props.amazonPurchases)})
                </span>
              </div>
            </a>
            <div
              className={`exact-shop-now exact-shop-now-amazon`}
              style={{ position: "relative" }}
            >
              <div
                className="exact-logo-icon"
                style={{
                  position: "absolute",
                  top: -10,
                  right: -15,
                  width: "40px",
                  height: "40px",
                }}
              >
                <img
                  src={amazonLogo}
                  alt={`${props.source} logo`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </div>
              Buy Now
            </div>
          </div>
          <div className="exact-right-info">
            <a
              href={props.temuAffiliateLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => trackButtonClick("Exact Temu Affiliate", props.id)}
            >
              <div className="exact-store-container">
                <div className="exact-discount-info">
                  {props.discount === "0%"
                    ? "Re-stocked"
                    : `${props.temuDiscount} OFF`}
                </div>
              </div>
              <div className="exact-price-info">${props.temuCurrentPrice}</div>
              <div className={`exact-price-info-amazon-aux `}>&nbsp;</div>
              <div className="exact-rating-info">
                <FaStar className="exact-star-icon" />
                <span className="exact-rating">
                  {props.temuRating || "N/A"}
                </span>
                <span className="exact-purchases">
                  {props.temuPurchases
                    ? `(${numberFormatter.format(props.temuPurchases)})`
                    : ""}
                </span>
              </div>
            </a>
            <div
              className={`exact-shop-now exact-shop-now-temu`}
              style={{ position: "relative" }}
            >
              <div
                className="exact-logo-icon"
                style={{
                  position: "absolute",
                  top: -10,
                  left: -15,
                  width: "40px",
                  height: "40px",
                }}
              >
                <img
                  src={temuLogo}
                  alt={`${props.source} logo`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </div>
              Buy Now
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
});

export default ProductComparison;
