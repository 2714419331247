import React from "react";
import ProductComparison from "./ProductComparison/ProductComparison";
import { usePageViews, useDocumentTitle } from "../utils/analytics";

function ExactPage({ products = [], lastProductElementRef, isFetchingExact }) {
  usePageViews("Exact Page");

  return (
    <div className="exact-comparison-container">
      {!isFetchingExact && products.length === 0 ? (
        <p className="no-products-message">
          No products available. Please check back later or change your filter
          options.
        </p>
      ) : (
        products.map((product, index) => {
          const isLastElement = products.length === index + 1;
          return (
            <div
              className="exact-comparison"
              ref={isLastElement ? lastProductElementRef : null}
            >
              <ProductComparison
                ref={isLastElement ? lastProductElementRef : null}
                key={index}
                id={product.id}
                name={product.name}
                thumbnail={product.thumbnail}
                category={product.category}
                temuCurrentPrice={product.temu.current_price}
                temuOriginalPrice={product.temu.original_price}
                temuIsSoldOut={product.temu.is_sold_out}
                temuDiscount={product.temu.discount_rate}
                temuRating={product.temu.score}
                temuPurchases={product.temu.review_number}
                temuAffiliateLink={product.temu.affiliate_link}
                amazonCurrentPrice={product.amazon.current_price}
                amazonOriginalPrice={product.amazon.original_price}
                amazonIsSoldOut={product.amazon.is_sold_out}
                amazonDiscount={product.amazon.discount_rate}
                amazonRating={product.amazon.score}
                amazonPurchases={product.amazon.review_number}
                amazonNewPrice={product.amazon.price_new}
                amazonUsedPrice={product.amazon.price_used}
                amazonAffiliateLink={product.amazon.affiliate_link}
                soldout={false}
              />
            </div>
          );
        })
      )}
    </div>
  );
}

export default ExactPage;
