import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./Apps/App";
import KitchenSimilar from "./Apps/KitchenSimilar";
import KitchenExact from "./Apps/KitchenExact";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga4";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Link,
} from "react-router-dom";
// Initialize ReactGA with your tracking ID
ReactGA.initialize("G-NS1HBMQ0R5");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/1" element={<KitchenExact />} />
      <Route path="/2" element={<KitchenSimilar />} />
    </Routes>
  </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// npm run build
// gcloud app deploy
