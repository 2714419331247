import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const trackButtonClick = (action, trackingInfo) => {
  ReactGA.event({
    category: "Button",
    action: action,
    label: trackingInfo,
  });
};

export function usePageViews(pageTitle) {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: pageTitle,
    });
  }, [location]);
}

export const trackComponentDisplay = (componentName, additionalData = {}) => {
  ReactGA.event({
    category: "Component",
    action: "Display",
    label: componentName,
    ...additionalData,
  });
};

export const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};
